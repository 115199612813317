import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { MainLayout } from "../../../layouts/main.layout"
import StandardPageHeader from "../../../components/layout-components/top/headers/standard-page-header/StandardPageHeader.component"
import PageSection from "../../../layouts/PageSection/PageSection.component"
import Footer from "../../../components/layout-components/bottom/footer/Footer.component"
import { loadDynamicScript } from "../../../utils/loadScript";

import { header } from "./css/header.css"
import { blogPostSection } from "./css/blogPostSection.css"
import { sliceByWord } from "../../../utils/sliceByWord"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"
import { replaceHtmlEntities } from "../../../utils/replaceHtmlEntities"

// @TODO - Find extracted interface for this.
interface BlogPostPropsI {
  data: {
    wordpressPost: {
      title: string,
      content: string,
      slug: string,
      featured_media: {
        source_url: string
      }
    }
  },
  edges: any[] // @TODO - types for contents of this array
}

const BlogPost: React.FC<BlogPostPropsI> = (props) => {

  const post = props.data.wordpressPost;

  // Cut the description to 300 letters and trim html tags.
    // description = sliceByWord(post.content, 300).replace(/<(?<=<)(.*?)(?=>)>/g, '');
    // @TODO - Because of the Firefox erroring on this regexp, we are trying the simpler one.
    // This regexp removed any html blocks/tags, and only outputed the raw text.
    // -- https://stackoverflow.com/questions/49816707/firefox-invalid-regex-group
    // -- https://bugzilla.mozilla.org/show_bug.cgi?id=1425763

  const description = sliceByWord(post.content, 300).replace(/<[^>]*>?/gm, '');

  const meta = {
    title: post.title,
    description: description
  }

  const og = {
    image: post.featured_media.source_url
  } 

  const pageSlug = `/blog/${post.slug}`;

  const shouldIncludeInstagramEmbed: boolean = post.content.includes('src="//www.instagram.com/embed.js') ? true : false; 

  useEffect(() => {

    if (shouldIncludeInstagramEmbed) {

      loadDynamicScript(
        (alreadyLoaded) => {
          if (alreadyLoaded) {
            (window as any)["instgrm"] && (window as any).instgrm.Embeds.process();
          }
        }, 
        'instagram-embed', 
        '//www.instagram.com/embed.js'
      );
    }

  })

  // Replace intern blog posts urls from blog.podroomcreative.com to podroomcreative.com/blog
  post.content = post.content.replace('href="https://blog.podroomcreative.com/', 'href="https://podroomcreative.com/blog/');
  post.content = post.content.replace('href="http://blog.podroomcreative.com/', 'href="https://podroomcreative.com/blog/');

  // Fix mixed-content (HTTP => HTTPS).
  post.content = post.content.replace('src="http://blog.podroomcreative.com"', 'src="https://blog.podroomcreative.com"');
  
  return (

    <MainLayout meta={meta} og={og} canonicalUrl={getCanonicalUrl(pageSlug)}>



      {/* 
      
            Header

      */}

      <StandardPageHeader 
        extendStyles={header}
        data={
          {
            heading: replaceHtmlEntities(post.title),
            // backgroundImage: post.featuredImage ?? undefined
          }
        } 
      />



      {/* 
    
            Blog post content

      */}

      <PageSection extendStyles={blogPostSection}>
        
        <div 
          className="post-content"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
    
      </PageSection>



      {/* 
      
            Footer

      */}

      <Footer />


    </MainLayout>

  )

}

export default BlogPost;

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      slug
      featured_media {
        source_url
      }
    }
  }
`