import { css } from "styled-components"

export const blogPostSection = css`

  max-width: 960px;
  margin: 0 auto;
  margin-bottom: 150px;

  .post-content {

    p, ul, li { 
      font-size: 20px;
      line-height: 1.3;

      a {
        font-size: 20px;
      }
    }

    p {
      margin: 20px 0;
    }

    a {
      font-weight: bold;
      /* color: #AA007C;
      color: #00a1aa; */
      background: linear-gradient(#00a1aa 20%, #AA007C 60%, black 5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-decoration: underline;
      position: relative;
      z-index: 2;

      :before {
        content: " ";
        background-color: #ffed0f66;
        position: absolute;
        width: calc(100% + 6px);
        height: 100%;
        z-index: -1;
        left: -3px;
        transform: scaleX(0);
        transform-origin: left center; 
        transition: transform 300ms;
        transition-timing-function: ease-out;
      }

      :hover {
        &:before {
          transform: scaleX(1);
        }
      }

      :after {
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 1px;
        background: black;
        content: " ";
      }
    }

    h1, h2 { 
      margin-top: 50px;
      margin-bottom: 30px;
    }

    h3, h4, h5 { 
      margin-top: 25px;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 50px;
    }
    h2 {
      font-size: 42px;
    }
    h3 {
      font-size: 36px;
    }
    h4 {
      font-size: 32px;
    }
    h5 {
      font-size: 28px;
    }

    img { 
      margin-top: 20px;
      margin-bottom: 20px;
    }

    figure {
      margin: 0;
    }

    .embed-container {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    blockquote, .instagram-media {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }

  }

  

`