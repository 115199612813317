import { css } from "styled-components"

export const header = css`

  .content {

    h1.heading {
      font-size: 50px;
      line-height: 60px;
      max-width: 860px;
      margin: 0 auto;

      @media (max-width: 768px) {
        font-size: 42px;
        line-height: 50px;
      }
    }

  }

`